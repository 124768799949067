/* eslint-disable react/jsx-filename-extension */
import React from "react";
import { graphql } from "gatsby";
import resolveImage from "@dataResolvers/resolveImage";
import resolvePageBuilder from "@dataResolvers/resolvePageBuilder";
import PageTemplate from "@pageTemplates/PagesDefault";
// eslint-disable-next-line no-unused-vars
import PageBuilderFragment from "@fragments/PageBuilderFragment";
import { useAppState } from "@state";
import QueryContainer, { HeadTemplate } from "../QueryContainer";

export const query = graphql`
  query PageQuery($uri: [String]) {
    craft {
      entry(uri: $uri) {
        ... on Craft_pages_default_Entry {
          enableAbTest
          heroHeading: heading0
          heroDescriptor: descriptor0
          heroButton: link0 {
            text
            url
          }
          heroSecondaryLink: link1 {
            text
            url
          }
          heroBackgroundImage: image0 {
            width
            height
            hasFocalPoint
            slug
            status
            title
            focalPoint
            url
          }
          heroStyle
          heroBackgroundColor: backgroundColor
          pageBuilder {
            ...PageBuilderFragment
          }
          # Meta
          slug
          url
          title
          metaTitle
          metaDescription
          metaImage {
            width
            height
            hasFocalPoint
            slug
            status
            title
            focalPoint
            url
          }
        }
      }
      # b: entry(uri: $uri, siteId: 2) {
      #   ... on Craft_pages_default_Entry {
      #     heroHeading: heading0
      #     heroDescriptor: descriptor0
      #     heroButton: link0 {
      #       text
      #       url
      #     }
      #     heroSecondaryLink: link1 {
      #       text
      #       url
      #     }
      #     heroBackgroundImage: image0 {
      #       width
      #       height
      #       hasFocalPoint
      #       slug
      #       status
      #       title
      #       focalPoint
      #       url
      #     }
      #     heroStyle
      #     heroBackgroundColor: backgroundColor
      #     pageBuilder {
      #       ...PageBuilderFragment
      #     }
      #     # Meta
      #     slug
      #     url
      #     title
      #     metaTitle
      #     metaDescription
      #     metaImage {
      #       width
      #       height
      #       hasFocalPoint
      #       slug
      #       status
      #       title
      #       focalPoint
      #       url
      #     }
      #   }
      # }
    }
  }
`;

const dataResolver = ({ entry, b }, test) => {
  const {
    pageBuilder,
    heroHeading,
    heroDescriptor,
    heroButton,
    heroSecondaryLink,
    heroBackgroundImage,
    heroStyle,
    heroBackgroundColor,
    // Meta
    url,
    title,
    metaTitle,
    metaDescription,
    metaImage,
    slug,
  } = test && entry.enableAbTest ? b : entry;

  return {
    hero: {
      heading: heroHeading,
      descriptor: heroDescriptor,
      button: heroButton,
      link: heroSecondaryLink,
      image: resolveImage(heroBackgroundImage),
      separate: heroStyle !== "fullBleed",
      color: heroBackgroundColor,
    },
    blocks: resolvePageBuilder(pageBuilder),
    meta: {
      url,
      title,
      metaPageTitle: metaTitle,
      metaDescription,
      metaFeaturedImage: resolveImage(metaImage),
      slug,
    },
  };
};

export const Head = ({ data, ...rest }) => {
  const [{ layout }] = useAppState();
  const { test } = layout;
  const { craft } = data;
  const resolvedData = dataResolver(craft, test);
  return <HeadTemplate {...rest} data={resolvedData} />;
};

const Page = props => (
  <QueryContainer
    template={PageTemplate}
    dataResolver={dataResolver}
    queryName="PagesDefaultQuery"
    {...props}
  />
);

export default Page;
